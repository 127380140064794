// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  text-align: center;
  background-color: #00796b;
  color: white;
  padding: 20px;
}

.app-header {
  margin-bottom: 40px;
}

.logo {
  width: 100px; 
  margin-bottom: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .logo {
      width: 80px; 
  }
  
  .app-header {
      padding: 10px; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA,sBAAsB;AACtB;EACE;MACI,WAAW;EACf;;EAEA;MACI,aAAa;EACjB;AACF","sourcesContent":[".app {\n  text-align: center;\n  background-color: #00796b;\n  color: white;\n  padding: 20px;\n}\n\n.app-header {\n  margin-bottom: 40px;\n}\n\n.logo {\n  width: 100px; \n  margin-bottom: 20px;\n}\n\n/* Responsive Styles */\n@media (max-width: 768px) {\n  .logo {\n      width: 80px; \n  }\n  \n  .app-header {\n      padding: 10px; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
