// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #00796b; 
    color: white; 
    text-align: center; 
    padding: 20px; 
    width: 100%; 
}

.footer-content {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
}

.footer-links {
    margin-top: 10px; 
}

.footer-links a {
    color: white; 
    text-decoration: none; 
    margin: 0 10px; 
}

.footer-links a:hover {
    text-decoration: underline; 
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".footer {\n    background-color: #00796b; \n    color: white; \n    text-align: center; \n    padding: 20px; \n    width: 100%; \n}\n\n.footer-content {\n    display: flex; \n    flex-direction: column; \n    align-items: center; \n}\n\n.footer-links {\n    margin-top: 10px; \n}\n\n.footer-links a {\n    color: white; \n    text-decoration: none; \n    margin: 0 10px; \n}\n\n.footer-links a:hover {\n    text-decoration: underline; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
