import React from 'react';
import './ContactIcon.css'; 

const ContactIcons = () => {
  const whatsappNumber = '918080700999'; 
  const callNumber = '8080700999'; 

  return (
    <div className="contact-icons">
      <a
        href={`https://api.whatsapp.com/send/?phone=${whatsappNumber}&text&type=phone_number&app_absent=0`} 
        target="_blank"
        rel="noopener noreferrer"
        className="icon whatsapp"
      >
        <img src="/assets/images/whatsapp.png" alt="WhatsApp" />
      </a>
      <a
        href={`tel:+91${callNumber}`} 
        className="icon call"
      >
        <img src="/assets/images/phone_call.png" alt="Call" />
      </a>
    </div>
  );
};

export default ContactIcons;
