import React from 'react';
import AppHeader from './components/Header/AppHeader'; 
import HotelList from './components/HotelList/HotelList'; 
import Footer from './components/Footer/Footer'; 
import './App.css'; 

function App() {
    return (
        <div className="app">
            <AppHeader /> 
            <HotelList /> 
            <Footer />
        </div>
    );
}

export default App;
