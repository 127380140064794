// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-icons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px; /* Space between icons */
    z-index: 1000; /* Ensure icons are above other content */
  }
  
  .icon {
    width: 50px; /* Set icon size */
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff; /* Background color */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
    transition: transform 0.2s; /* Smooth scaling effect */
  }
  
  .icon:hover {
    transform: scale(1.1); /* Scale effect on hover */
  }
  
  .icon img {
    width: 50px; /* Set icon image size */
    height: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ContactIcon/ContactIcon.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAE,wBAAwB;IACnC,aAAa,EAAE,yCAAyC;EAC1D;;EAEA;IACE,WAAW,EAAE,kBAAkB;IAC/B,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB,EAAE,qBAAqB;IAC7C,yCAAyC,EAAE,kBAAkB;IAC7D,0BAA0B,EAAE,0BAA0B;EACxD;;EAEA;IACE,qBAAqB,EAAE,0BAA0B;EACnD;;EAEA;IACE,WAAW,EAAE,wBAAwB;IACrC,YAAY;EACd","sourcesContent":[".contact-icons {\n    position: fixed;\n    bottom: 20px;\n    right: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 25px; /* Space between icons */\n    z-index: 1000; /* Ensure icons are above other content */\n  }\n  \n  .icon {\n    width: 50px; /* Set icon size */\n    height: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    background-color: #fff; /* Background color */\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow effect */\n    transition: transform 0.2s; /* Smooth scaling effect */\n  }\n  \n  .icon:hover {\n    transform: scale(1.1); /* Scale effect on hover */\n  }\n  \n  .icon img {\n    width: 50px; /* Set icon image size */\n    height: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
