import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>© 2024 The Byke Hotels. All rights reserved.</p>
                <div className="footer-links">
                    <a href="https://thebyke.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    <span>|</span>
                    <a href="https://thebyke.com/terms-conditions/" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
