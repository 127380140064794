// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.header-content {
    text-align: center;
}

.logo {
    width: 200px; 
    height: auto;
    margin-bottom: 10px; 
}

h1 {
    font-size: 2rem;
    margin-top: 0; 
}

p {
    font-size: 1.2rem;
    margin-top: 5px; 
}

/* Social Media icons in a row */
.social-media {
    display: flex;
    gap: 20px; 
    justify-content: center;
    align-items: center;
    margin-top: 15px; 
}

.social-media a,
.bell-icon {
    display: inline-block;
}

.social-media img {
    width: 40px;
    height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/AppHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA,gCAAgC;AAChC;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".app-header {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 20px;\n}\n\n.header-content {\n    text-align: center;\n}\n\n.logo {\n    width: 200px; \n    height: auto;\n    margin-bottom: 10px; \n}\n\nh1 {\n    font-size: 2rem;\n    margin-top: 0; \n}\n\np {\n    font-size: 1.2rem;\n    margin-top: 5px; \n}\n\n/* Social Media icons in a row */\n.social-media {\n    display: flex;\n    gap: 20px; \n    justify-content: center;\n    align-items: center;\n    margin-top: 15px; \n}\n\n.social-media a,\n.bell-icon {\n    display: inline-block;\n}\n\n.social-media img {\n    width: 40px;\n    height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
