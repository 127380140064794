import React from 'react';
import './AppHeader.css'; 

const AppHeader = () => {
    return (
        <header className="app-header">
            <div className="header-content">
                <div className="header-text">
                    <img
                        src="/assets/images/the_byke_logo.png"  
                        alt="The Byke Logo"
                        className="logo"
                    />
                    <h1>The Byke Hotels & Resorts</h1>
                    <p>15 Hotels and Resorts across India Where the road ends, the journey begins.</p>
                </div>
            </div>
            <div className="social-media">
                <div className="bell-icon">
                    <img src="/assets/images/bell_icon.png" alt="Subscribe" />
                </div>
                <a href="https://www.instagram.com/thebykehotels.india" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/Instagram_icon.png" alt="Instagram" />
                </a>
                <a href="mailto:example@example.com">
                    <img src="/assets/images/email_icon.png" alt="Email" />
                </a>
            </div>
        </header>
    );
};

export default AppHeader;
