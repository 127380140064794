import React from 'react';
import './HotelList.css';
// import ContactForm from '../ContactForm/ContactForm'
import ContactIcons from '../ContactIcon/ContactIcon'; 

const hotels = [
  {
    id: 1,
    name: 'The Byke Royal Pearl - North Goa',
    description: 'Dive into the vibrant pulse of North Goa with a stay just minutes from iconic beaches and electrifying nightlife.',
    bookingLink: "https://royalpearl-northgoa.thebyke.com/property",
    image: '/assets/images/north_goa.png'
  },
  {
    id: 2,
    name: 'The Byke Old Anchor - South Goa',
    description: 'Immerse yourself in tropical serenity just steps away from the pristine beaches of South Goa.',
    bookingLink: "https://oldanchor-southgoa.thebyke.com/property",
    image: '/assets/images/south_goa.png'
  },
  {
    id: 3,
    name: 'The Byke Sunshine Grand - Ooty',
    description: 'Escape to the misty hills of Ooty and experience tranquility in a perfect hill station hideaway.',
    bookingLink: "https://sunshinegrand-ooty.thebyke.com/property",
    image: '/assets/images/ooty.png'
  },
  {
    id: 4,
    name: 'The Byke Neelkanth - Manali',
    description: 'Book a #MountainStaycation with a wide view of Rohtang Valley',
    bookingLink: "https://neelkanth-manali.thebyke.com/property",
    image: '/assets/images/manali.png'
  },
  {
    id: 5,
    name: 'The Byke Puja Samudra - Kovalam',
    description: 'Your beachside paradise awaits, just 100 meters from the golden sands of Kovalam.',
    bookingLink: "https://pujasamudra-kovalam.thebyke.com/property",
    image: '/assets/images/kovalam.png'
  },
  {
    id: 6,
    name: 'The Byke Nature Vilas - Shimla',
    description: 'Experience the magic of the mountains with serene forest views and breathtaking valleys right at your doorstep.',
    bookingLink: "https://naturevilas-shimla.thebyke.com/property",
    image: '/assets/images/shimla.png'
  },
  {
    id: 7,
    name: 'The Byke Grassfield Resort - Jaipur',
    description: 'Discover the royal charm of Jaipur with a luxurious staycation in the Pink City.',
    bookingLink: "https://grassfield-jaipur.thebyke.com/property",
    image: '/assets/images/jaipur.png'
  },
  {
    id: 8,
    name: 'The Byke Heritage Resort - Matheran',
    description: 'Step into a world of elegance with our premium heritage resort nestled in the heart of Matheran’s greenery.',
    bookingLink: "https://heritage-matheran.thebyke.com/property",
    image: '/assets/images/matheran_heritage.png'
  },
  {
    id: 9,
    name: 'The Byke Brightland Resort - Matheran',
    description: 'Get closer to nature at our resort, which is perfect for a peaceful retreat.',
    bookingLink: "https://brightland-matheran.thebyke.com/property",
    image: '/assets/images/matheran_brightland.png'
  },
  {
    id: 10,
    name: 'The Byke Suraj Club - Junagadh',
    description: 'Whether it\'s a spiritual journey or a relaxing escape, Junagadh\'s welcoming embrace awaits you.',
    bookingLink: "https://surajclub-junagadh.thebyke.com/property",
    image: '/assets/images/junagadh.png'
  },
  {
    id: 11,
    name: 'The Byke Suraj Plaza - Thane',
    description: 'Combine work and pleasure in style with our modern, vibrant accommodations for business trips and staycations.',
    bookingLink: "https://surajplaza-thane.thebyke.com/property",
    image: '/assets/images/thane_surajplaza.png'
  },
  {
    id: 12,
    name: 'The Byke Studio Apartments - Thane',
    description: 'Comfort meets affordability with our budget-friendly studio apartments in the heart of Thane.',
    bookingLink: "https://studioapartment-thane.thebyke.com/property",
    image: '/assets/images/thane_studioapt.png'
  },
  {
    id: 13,
    name: 'The Byke Delotel - Borivali, Mumbai',
    description: 'Business or leisure, enjoy Mumbai’s hustle with a dash of comfort and luxury in Borivali.',
    bookingLink: "https://delotel-borivali.thebyke.com/property",
    image: '/assets/images/borivali_delotel.png'
  },
  {
    id: 14,
    name: 'The Byke Signature - Bangalore',
    description: 'Take a break from the fast-paced city life and unwind with our signature blend of relaxation and convenience.',
    bookingLink: "https://signature-bangalore.thebyke.com/property",
    image: '/assets/images/bangalore-signature.jpg'
  },
  {
    id: 15,
    name: 'The Byke Niranjana - Bodhgaya',
    description: 'Experience serenity in the holy city of Bodh Gaya with a stay rooted in peace and spirituality.',
    bookingLink: "https://thebykeniranjanaresort.bookingjini.com/property/",
    image: '/assets/images/bodhgaya.png'
  },
  {
    id: 16,
    name: 'The Byke Shanti Villa - Mahabaleshwar',
    description: 'Discover the charm of Mahabaleshwar in a heritage setting, perfect for those seeking relaxation.',
    bookingLink: "",
    image: '/assets/images/mahabaleshwar.png'
  },
  {
    id: 17,
    name: 'The Byke Majestic - Palghar',
    description: 'Unwind in Palghar’s tranquil surroundings with a majestic stay that redefines comfort.',
    bookingLink: "",
    image: '/assets/images/palghar.png'
  },
  {
    id: 18,
    name: 'The Byke Embassy - Surat',
    description: 'Elevate your Surat experience with a perfect blend of luxury and warmth.',
    bookingLink: "",
    image: '/assets/images/surat.png'
  },
  {
    id: 19,
    name: 'The Byke Suresh Plaza - Nashik',
    description: 'A perfect stay for exploring Nashik’s rich heritage and vineyards in complete comfort.',
    bookingLink: "",
    image: '/assets/images/nashik.png'
  },
  {
    id: 20,
    name: 'The Byke Skylark Seafront Resort - Dapoli ',
    description: 'Let the sound of the waves be your backdrop at this beachfront escape in Dapoli.',
    bookingLink: "",
    image: '/assets/images/dapoli.png'
  },
];

const HotelList = () => {
  return (
    <>
      <div className="hotel-list">
        {hotels.map(hotel => (
          <div className="hotel-card" key={hotel.id}>
            <img src={hotel.image} alt={hotel.name} className="hotel-image" loading="lazy" />
            <div className="overlay">
             
            </div>
            <div className="hotel-info">
              <h3 className="hotel-name">{hotel.name}</h3>
              <p className="hotel-description">{hotel.description}</p>
            </div>
             {hotel.bookingLink ? (
                <a href={hotel.bookingLink} className="booking-link" target="_blank" rel="noopener noreferrer">
                  Book Now
                </a>
              ) : (
                <a href={hotel.bookingLink} className="booking-link" target="_blank" rel="noopener noreferrer">
                Book Now
              </a>
              )}
          </div>
          
        ))}
      </div>
      {/* <ContactForm /> */}
      <ContactIcons /> 
    </>
  );
};

export default HotelList;